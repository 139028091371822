import { useNavigate, A } from '@solidjs/router';
import { Show, For } from 'solid-js';
import IconBack from '~/assets/icons/backArrow.svg';
import { BACK } from '~/assets/strings';
import { useLocalization } from '~/hooks/useLocalization';

interface AreaBreadcrumbProps {
  showBack: boolean;
  data: {
    name: string;
    path?: string;
  }[];
}

export const AreaBreadcrumb = (props: AreaBreadcrumbProps) => {
  const navigate = useNavigate();
  const { t } = useLocalization();

  return (
    <div class="w-full px-4 sm:px-0">
      <div class="flex flex-wrap items-center gap-y-2">
        <Show when={props.showBack}>
          <>
            <div class="flex cursor-pointer items-center gap-1 text-sm sm:gap-2 sm:text-base" onClick={() => navigate(-1)}>
              <img src={IconBack} alt="back" class="w-4 sm:w-5" />
              {t(BACK)}
            </div>
            <div class="mx-3 h-6 w-px bg-border-level01 sm:mx-[30px] sm:h-8 sm:w-[2px]" />
          </>
        </Show>

        <div class="flex flex-wrap items-center gap-x-1 gap-y-2 text-sm sm:gap-x-2 sm:text-base">
          <For each={props.data}>
            {(item, index) => (
              <div class="flex items-center gap-1 sm:gap-2">
                <Show when={item.path} fallback={<span class="break-all">{item.name}</span>}>
                  <A href={item.path!} class="break-all text-text-level03 hover:underline">
                    {item.name}
                  </A>
                </Show>
                <Show when={index() < props.data.length - 1}>
                  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg" class="shrink-0">
                    <path
                      d="M1.46226 9.45947L0.543017 8.54023L4.0834 4.99985L0.543017 1.45947L1.46226 0.540234L5.92188 4.99985L1.46226 9.45947Z"
                      fill="#8D9BBD"
                    />
                  </svg>
                </Show>
              </div>
            )}
          </For>
        </div>
      </div>
    </div>
  );
};
