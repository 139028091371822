import { createSignal, createEffect, splitProps, For, createMemo } from 'solid-js';
import type { Component, ComponentProps } from 'solid-js';
import { IconChevronLeft, IconChevronUpDown } from '~/components/ui/Icons';
import { useLocalization } from '~/hooks/useLocalization';

type Day = { day: number; active?: boolean; selected?: boolean; today?: boolean };

type SelectorProps = Omit<ComponentProps<'div'>, 'selected' | 'onSelect'> & {
  selected?: Date;
  onSelect?: (date: Date) => void;
  yearType?: 'general' | 'birthday';
  disabledDate?: (date: Date) => boolean;
};

const createDate = (input?: Date | string) => {
  const date = input ? new Date(input) : new Date();
  date.setHours(0, 0, 0, 0);
  return date;
};

const YearSelector: Component<SelectorProps> = (props) => {
  const [params, rest] = splitProps(props, ['selected', 'onSelect', 'yearType']);

  const current = createMemo(() => createDate(params.selected).getFullYear());

  const years = () => {
    const focus = current();
    let yearRange: number[] = [];
    if (params.yearType === 'birthday') {
      yearRange = Array.from({ length: 101 }, (_, i) => focus - 100 + i);
    } else {
      yearRange = Array.from({ length: 61 }, (_, i) => focus - 50 + i);
    }
    return yearRange;
  };

  return (
    <div {...rest}>
      <For each={years()}>
        {(year) => (
          <span
            class="aria-selected:bg-primary aria-[selected='false']:hover:bg-primary/20 cursor-pointer rounded p-2 text-center aria-selected:text-white"
            onClick={() => {
              const newDate = createDate(params.selected);
              newDate.setFullYear(year);
              params.onSelect?.(newDate);
            }}
            aria-selected={year === current()}>
            {year}
          </span>
        )}
      </For>
    </div>
  );
};

const MonthSelector: Component<SelectorProps> = (props) => {
  const [params, rest] = splitProps(props, ['selected', 'onSelect']);
  const { currentLanguage } = useLocalization();

  const current = createMemo(() => createDate(params.selected).getMonth());

  const months = () => {
    return Array.from({ length: 12 }, (_, i) => i);
  };

  return (
    <div {...rest}>
      <For each={months()}>
        {(month) => (
          <span
            class="aria-selected:bg-primary aria-[selected='false']:hover:bg-primary/20 cursor-pointer rounded p-2 text-center aria-selected:text-white"
            onClick={() => {
              const newDate = createDate(params.selected);
              newDate.setDate(1);
              newDate.setMonth(month);
              params.onSelect?.(newDate);
            }}
            aria-selected={month === current()}>
            {new Date(0, month).toLocaleDateString(currentLanguage(), { month: 'short' })}
          </span>
        )}
      </For>
    </div>
  );
};

const Calendar: Component<SelectorProps> = (props) => {
  const [params, rest] = splitProps(props, ['selected', 'onSelect', 'yearType', 'disabledDate']);

  const { t, currentLanguage } = useLocalization();

  const [focus, setFocus] = createSignal<Date>(createDate());
  const [selected, setSelected] = createSignal<Date>();
  const [isSelectedYM, setIsSelectedYM] = createSignal(false);

  createEffect(() => {
    setSelected(params.selected ? createDate(params.selected) : undefined);
    params.selected && setFocus(createDate(params.selected));
  });

  let ref: HTMLDivElement | undefined;

  createEffect(() => {
    if (!isSelectedYM() || ref == null) {
      return;
    }
    ref.querySelector('[aria-selected=true]')?.scrollIntoView({ block: 'center' });
  });

  const calendar = () => {
    const year = focus().getFullYear();
    const month = focus().getMonth();
    const selectedDate = selected();

    const totalDays = new Date(year, month + 1, 0).getDate();
    const firstDayOfWeek = new Date(year, month, 1).getDay();
    const lastDayOfWeek = new Date(year, month, totalDays).getDay();
    const lastDayOfPreviousMonth = new Date(year, month, 0).getDate();

    const isToday = (day: number) => {
      const today = createDate();
      return today.getDate() === day && today.getMonth() === month && today.getFullYear() === year;
    };

    const isSelected = (day: number) => {
      if (selectedDate == null) {
        return false;
      }
      return selectedDate.getDate() === day && selectedDate.getMonth() === month && selectedDate.getFullYear() === year;
    };

    const temp: Day[] = [
      ...Array.from({ length: firstDayOfWeek }, (_, i) => ({ day: lastDayOfPreviousMonth - firstDayOfWeek + i + 1 })),
      ...Array.from({ length: totalDays }, (_, i) => ({ day: i + 1, active: true, selected: isSelected(i + 1), today: isToday(i + 1) })),
      ...Array.from({ length: 6 - lastDayOfWeek }, (_, i) => ({ day: i + 1 })),
    ];

    return Array.from({ length: temp.length / 7 }, (_, i) => temp.slice(i * 7, i * 7 + 7));
  };

  const handleMonthChange = (inc: number) => {
    focus().setDate(1);
    focus().setMonth(focus().getMonth() + inc);
    setFocus(createDate(focus()));
  };

  const handleYearMonthSelect = (date: Date) => {
    setFocus(date);
    setIsSelectedYM(false);
  };

  const handleDaySelect = (day: Day, e: MouseEvent) => {
    if (isThisDayDisabled(day.day)) {
      return;
    }
    if (!day.active) {
      handleMonthChange(day.day < 15 ? 1 : -1);
    }
    const current = focus();
    current.setDate(day.day);
    setSelected(createDate(current));
    params.onSelect?.(createDate(current));
    e.stopImmediatePropagation();
  };

  const isThisDayDisabled = (day: number) => {
    if (params.disabledDate) {
      const current = new Date(focus());
      current.setDate(day);
      return params.disabledDate(current);
    }
    return false;
  };

  const isThisDayCanNotSelect = (day: Day) => {
    return !day.active || isThisDayDisabled(day.day);
  };

  return (
    <div {...rest} ref={ref}>
      <div class="flex w-80 items-center justify-between border-b px-6 pb-3 pt-4">
        <button class="hover:bg-hover-color/10 rounded transition-colors" onClick={() => handleMonthChange(-1)}>
          <IconChevronLeft />
        </button>
        <h2
          class="hover:bg-hover-color/10 flex cursor-pointer items-center rounded px-3 py-1 text-sm font-medium transition-colors"
          onClick={() => setIsSelectedYM((prev) => !prev)}>
          {focus().toLocaleDateString(currentLanguage(), { month: 'long', year: 'numeric' })}
          <IconChevronUpDown class="ml-1 size-3" />
        </h2>
        <button class="hover:bg-hover-color/20 rounded transition-colors" onClick={() => handleMonthChange(1)}>
          <IconChevronLeft class="rotate-180" />
        </button>
      </div>
      <div class="flex w-full gap-4 p-6 text-sm" classList={{ hidden: !isSelectedYM() }}>
        <YearSelector
          selected={focus()}
          onSelect={handleYearMonthSelect}
          class="scroll-mask thinscroll grid h-56 w-1/3 gap-1 overflow-y-auto py-2"
          yearType={params.yearType}
        />
        <MonthSelector selected={focus()} onSelect={handleYearMonthSelect} class="grid w-2/3 grid-cols-2 gap-1" />
      </div>
      <table class="mx-auto mb-4 mt-2.5 text-xs" classList={{ hidden: isSelectedYM() }}>
        <thead>
          <tr class="flex text-text-level03">
            <For each={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}>
              {(day) => <td class="flex size-10 items-center justify-center uppercase">{t(day)}</td>}
            </For>
          </tr>
        </thead>
        <tbody>
          <For each={calendar()}>
            {(week) => (
              <tr class="flex">
                <For each={week}>
                  {(day) => (
                    <td
                      class="m-1 flex size-8 cursor-pointer items-center justify-center rounded-full"
                      classList={{
                        'text-faded': isThisDayCanNotSelect(day),
                        'bg-hover-color text-white': day.selected,
                        'hover:bg-hover-color/20': !day.selected && !isThisDayCanNotSelect(day),
                        'border border-essential-colour': day.today,
                      }}
                      onClick={(e) => handleDaySelect(day, e)}>
                      {day.day}
                    </td>
                  )}
                </For>
              </tr>
            )}
          </For>
        </tbody>
      </table>
    </div>
  );
};

export { Calendar };
