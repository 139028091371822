type PrimitiveType = string | number | boolean | Date | undefined;

type Filter = { [key: string]: PrimitiveType | PrimitiveType[] | Filter };

export const filterToQuery = (filter: Filter | undefined, prefix = '', searchParams = new URLSearchParams()) => {
  if (filter == null) return '';

  for (const [key, value] of Object.entries(filter)) {
    if (value == null) continue;

    const name = prefix ? `${prefix}.${key}` : key;

    if (typeof value !== 'object') {
      searchParams.append(name, value.toString());
      continue;
    }

    if (value instanceof Date) {
      searchParams.append(name, value.toISOString());
      continue;
    }

    if (Array.isArray(value)) {
      value.forEach((v) => v != null && searchParams.append(name, v.toString()));
    } else {
      filterToQuery(value, name, searchParams);
    }
  }

  return searchParams.toString();
};

export const urlWithQuery = (url: string, queries: Filter | undefined) => {
  const query = filterToQuery(queries);
  return url + (query ? '?' + query : '');
};
