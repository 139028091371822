type Alignment = 'start' | 'end';
type Placement = 'top' | 'bottom' | 'left' | 'right';
type Position = { top: number; left: number; placement: Placement; alignment?: Alignment };
type ComputeOptions = { alignment?: Alignment; placement?: Placement; allowPlacements?: Placement[] };

const getMaxSpacePlacement = ({ top, right, bottom, left }: DOMRect, allowPlacements?: Placement[]): Placement => {
  bottom = window.innerHeight - bottom;
  right = window.innerWidth - right;
  const space = { top, right, bottom, left };
  allowPlacements = allowPlacements ?? (Object.keys(space) as Placement[]);
  return allowPlacements.reduce((a, b) => (space[a] > space[b] ? a : b));
};

export const computePosition = (
  reference: Element,
  floating: Element,
  { alignment, placement, allowPlacements }: ComputeOptions = {}
): Position => {
  const referenceRect = reference.getBoundingClientRect();
  const floatingRect = floating.getBoundingClientRect();

  placement = placement ?? getMaxSpacePlacement(referenceRect, allowPlacements);

  const actualRect = {
    top: referenceRect.top + window.scrollY,
    bottom: referenceRect.bottom + window.scrollY,
    left: referenceRect.left + window.scrollX,
    right: referenceRect.right + window.scrollX,
    width: referenceRect.width,
    height: referenceRect.height,
  };

  const position = { top: 0, left: 0, placement, alignment };

  if (placement === 'top' || placement === 'bottom') {
    if (placement === 'top') {
      position.top = actualRect.top - floatingRect.height;
    } else {
      position.top = actualRect.bottom;
    }
    if (alignment === 'start') {
      position.left = actualRect.left;
    } else if (alignment === 'end') {
      position.left = actualRect.right - floatingRect.width;
    } else {
      position.left = actualRect.left + actualRect.width / 2 - floatingRect.width / 2;
    }
  } else if (placement === 'left' || placement === 'right') {
    if (alignment === 'start') {
      position.top = actualRect.top + actualRect.height / 2 - floatingRect.height / 2;
    } else if (alignment === 'end') {
      position.top = actualRect.top + actualRect.height / 2 - floatingRect.height / 2;
    } else {
      position.top = actualRect.top + actualRect.height / 2 - floatingRect.height / 2;
    }
    if (placement === 'left') {
      position.left = actualRect.left - floatingRect.width;
    } else {
      position.left = actualRect.right;
    }
  }

  return position;
};

export const observeMove = (reference: Element, update: () => void) => {
  window.addEventListener('resize', update);
  window.addEventListener('scroll', update);
  let current: Element = reference;
  while (current.parentElement) {
    current = current.parentElement;
    current.addEventListener('scroll', update);
  }

  return () => {
    window.removeEventListener('resize', update);
    window.removeEventListener('scroll', update);
    let current: Element = reference;
    while (current.parentElement) {
      current = current.parentElement;
      current.removeEventListener('scroll', update);
    }
  };
};

export const observeSize = (reference: Element | Element[], update: () => void) => {
  const observer = new ResizeObserver(update);
  if (Array.isArray(reference)) {
    reference.forEach((r) => observer.observe(r));
  } else {
    observer.observe(reference);
  }
  return () => observer.disconnect();
};
