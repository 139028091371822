import { createEffect, onCleanup } from 'solid-js';
import type { Accessor } from 'solid-js';

type Container = HTMLElement | undefined | null;

export const useOutsideClick = (
  containers: Accessor<Container | Container[]>,
  enabled: Accessor<boolean>,
  callback: (e: MouseEvent, originalTarget: Element) => void
) => {
  let original: EventTarget | null = null;

  createEffect(() => {
    if (!enabled()) return;

    const handleMouseDown = (e: MouseEvent) => {
      original = e.target;
    };

    const handleClick = (e: MouseEvent) => {
      const target = original as Element | null;
      if (target == null) return;

      const cts = containers();
      const elements = Array.isArray(cts) ? cts : [cts];

      if (elements.some((i) => i?.contains(target))) return;

      const dialogId = target.closest('[role="dialog"]')?.id;
      if (dialogId != null) {
        const dc = document.querySelector(`[aria-controls="${dialogId}"]`);
        if (dc && elements.some((i) => i?.contains(dc))) return;
      }

      callback(e, target);

      original = null;
    };

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('click', handleClick);

    onCleanup(() => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('click', handleClick);
    });
  });
};
