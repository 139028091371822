import { mergeProps } from 'solid-js';
import IconBackArrow from '~/assets/icons/backArrow.svg';
import { cn } from '~/utils/classnames';

interface ArrowProps {
  direction?: 'left' | 'right';
  onClick?: () => void;
  disabled?: boolean;
}

export const Arrow = (props: ArrowProps) => {
  const mergedProps = mergeProps({ direction: 'left' }, props);

  const onClick = () => {
    if (mergedProps.disabled) {
      return;
    }
    mergedProps.onClick?.();
  };

  return (
    <div
      onClick={onClick}
      class={cn(
        'cursor-pointer rounded-full border px-5 py-3 hover:bg-gray-100',
        mergedProps.direction === 'right' ? 'rotate-180' : '',
        props.disabled ? 'cursor-not-allowed opacity-50' : ''
      )}>
      <img src={IconBackArrow} />
    </div>
  );
};
